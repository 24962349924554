@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;700&display=swap");

:root {
  --color-main: #f68657;
  --color-text: #383a3f;
  --color-dark: #1f2124;
  --color-gray: #677;
  --color-bg: #f3f6f9;
  --color-light: #acb4bd;
  --color-lighter: #f9f9f9;
  --color-white: #fff;
  --color-border: #e0e3e6;
}

.trip-style {
  --color-text: #f5f6f7;
  --color-white: #f5f6f7;
  --color-gray: #999;
  --color-bg: #ffdeb1;
  --color-lighter: #292a2c;
  --color-dark: #56202d;
  --color-border: #ffdeb1;

  --color-primary-dark: #56202d;
  --color-primary: #8e3a52;
  --color-secondary: #ffdeb1;

  --color-text-default: #999;
  --color-text-light: #999;
  --color-text-dark: #2e3235;

  --color-button-disabled: #8e3a52;
}

/* BASE STYLES */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif;
  color: inherit;
  font-size: inherit;
  scroll-behavior: smooth;
}

html {
  min-height: 100%;
}

body {
  line-height: 1.8em;
  font-weight: 400;
  font-size: 16px;
}

a {
  text-decoration: none;
}

/* APP STYLES */

/* placeholder for the whole page */
.container {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  color: var(--color-text);
  background-image: url("../src/assets/images/travel-icons.webp");
  background-size: 1600px 884px; /* Set the fixed size of the background image */
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* box containing content within the container */
.app {
  display: inline-block;
  width: 100%;
  max-width: 700px;
  margin: 60px auto;
  padding: 2.5vh;
  border-radius: 10px;
  background-color: var(--color-dark);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
  position: relative;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-dark);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  width: 100px;
  height: 100px;
}

.app-header h1 {
  font-size: 50px;
  padding: 20px 20px 20px 0px;
  color: var(--color-secondary);
  font-weight: 800;
}

.app-header h1 .header-text:hover {
  text-decoration: underline;
}

.app-header h1 span:hover {
  text-decoration: none;
  /* Apply other styles here if needed */
}

.app-body {
  padding: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 40px;
  margin-top: 15px;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  background-color: var(--color-dark);
}

.footer-link {
  color: var(
    --color-secondary
  ); /* Set the link color to white (or any color you prefer) */
  margin: 0 15px; /* Add some margin to the left and right of each link */
  text-decoration: none; /* Remove the default underline from the links */
}

.footer-link:hover {
  text-decoration: underline; /* Add an underline when hovering over a link */
}

.app-footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.social-icon-container {
  background-color: var(--color-primary-dark);
  color: var(--color-secondary); /* Text color */
  border: none; /* Remove default button border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Hand cursor on hover */
  text-decoration: none; /* Remove default underline from link */
  display: inline-flex; /* Align icon and text */
  align-items: center; /* Vertical centering */
  font-size: 2em;
  box-sizing: border-box;
}

.social-icon-container:hover {
  background-color: var(--color-primary); /* lighter color on hover */
}

/* SHARE ITINERARY STYLES */
.app-share-itinerary {
  font-size: 1em;
  color: var(--color-secondary);
  font-weight: bold;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.social-icon-whatsapp {
  background-color: var(--color-primary-dark);
  color: var(--color-secondary); /* Text color */
  border: none; /* Remove default button border */
  border-radius: 4px; /* Rounded corners */
  padding: 2px 10px; /* Padding */
  margin: 0px 2px;
  cursor: pointer; /* Hand cursor on hover */
  text-decoration: none; /* Remove default underline from link */
  display: inline-flex; /* Align icon and text */
  align-items: center; /* Vertical centering */
  font-size: 2em;
  box-sizing: border-box;
}

.app-share-separator {
  color: var(--color-secondary);
  font-size: 1.4em;
  font-weight: bold;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* share itinerary + whatsapp combined container */
.share-itnerary-container {
  padding-left: 5px;
  margin: 0;
  border: none;
  border-radius: 4px;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* lighter color on container when hovering */
.share-itnerary-container:hover {
  background-color: var(--color-primary);
  text-decoration: underline;
}

/* Apply hover state to children */
.share-itnerary-container:hover .social-icon-whatsapp {
  background-color: var(--color-primary);
}

/* FORM STYLES */

.form-page {
  overflow-y: auto;
  background-color: var(--color-dark);
}

.form-header {
  font-size: 25px;
  /* color: var(--color-white); */
  color: var(--color-secondary);
  font-weight: 500;
  text-align: center;
  padding: 20px 20px 30px;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
}

/* .form-field-input, */
.form-field input[type="text"],
.form-field input[type="number"] {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  width: 35%;
  box-sizing: border-box;
  border: 2px solid var(--color-primary);
  box-shadow: 0px 0px 3px var(--color-primary);
  color: var(--color-text-default); /* set default color */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-field .react-datepicker-wrapper {
  width: 35%;
}

/* set the size of Datepicker container equal to the other form fields */
.form-field .react-datepicker__input-container [type="text"] {
  width: 100%;
  display: flex;
  justify-content: center;
}

.form-field input[type="text"]::placeholder,
.form-field input[type="number"]::placeholder {
  color: var(--color-text-light); /* set lighter color for default text */
}

.form-field input[type="text"]:not(:placeholder-shown),
.form-field input[type="number"]:not(:placeholder-shown) {
  color: var(--color-text-dark); /* set darker color for user input */
}

/* form more options buttons */
.form-field .more-options-btn {
  /* Box Model */
  box-sizing: border-box;
  width: 35%;
  padding: 0.5rem;
  border-radius: 0.25rem;

  /* Typography */
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-primary);

  /* Visual Styles */
  border: 2px solid var(--color-primary);
  background-color: var(--color-secondary);
  box-shadow: 0px 0px 3px var(--color-primary);

  /* Positioning and Display */
  position: relative; /* This is to position the icon relative to the button */
  overflow: hidden;
}

/* position the icon */
.form-field button svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%); /* Center the icon vertically */
}

/* form submit buttons */
.form-field .btn-generate-trip {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-secondary);
  width: 30%;
  box-sizing: border-box;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.form-field .btn-generate-trip:disabled {
  background-color: var(--color-button-disabled);
  opacity: 0.7;
  cursor: not-allowed;
}

.form-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 16px 0px 16px;
}

/* TRIP GUIDE STYLES */

.trip-guide {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 10px;
  /* padding: 16px; */
}

.trip-itinerary h4 {
  font-size: 24px;
  margin-bottom: 16px;
}

/* TAB HEADER STYLES */
.tabs {
  display: flex;
  justify-content: start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tabs button {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border: 2px solid var(--color-primary);
  border-radius: 0.5rem;
  padding: 10px 20px;
  margin-right: 10px; /* Spacing between buttons */
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease; /* Smooth transition for hover and active states */
}

.tabs button:hover {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: bold;
  border: 4px solid var(--color-primary);
}

.tabs button.active {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: bold;
}

/* ITINERARY STYLES */
.location-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/* .day-activities {
  margin-bottom: 32px;
} */

.stream-activities {
  white-space: pre-wrap;
}

.trip-itinerary h5 {
  font-size: 20px;
  color: var(--color-secondary);
  margin-bottom: 10px;
}

.activity-container {
  display: flex;
  align-items: start;
  /* justify-content: center; */
  gap: 10px;
  padding: 10px;
}

/* Target every even .activity-container */
.activity-container:nth-child(odd) {
  flex-direction: row-reverse;
}

.activity-description {
  line-height: 1.4;
  margin-left: 10px;
  margin-bottom: 20px;
  flex: 1;
}

.activity-image img {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border-color: var(--color-secondary);
  border-style: solid;
  border-width: 2px;
}

/* set the link styles */
.trip-itinerary a {
  font-weight: bold;
  color: var(--color-secondary);
}

.trip-itinerary a:hover {
  text-decoration: underline;
}

/* FACTSHEET STYLES */

.country-factsheet h4 {
  font-size: 24px;
  margin-bottom: 16px;
}

.country-factsheet h5 {
  font-size: 20px;
  color: var(--color-secondary);
  margin-bottom: 10px;
}

.factsheet-key {
  display: block;
  color: var(--color-secondary);
  font-size: 15px;
}

.factsheet-teaser {
  margin-bottom: 16px;
}

.factsheet-teaser p {
  font-size: 15px;
  line-height: 1.4;
}

.factsheet-items {
  margin-bottom: 16px;
}

.factsheet-items p {
  /* font-size: 15px; */
  line-height: 1.4;
}

/* MAP STYLES */

.map-container {
  height: 400px; /* Adjust as necessary */
  width: 100%;
  border-radius: 10px;
  border-color: var(--color-secondary);
  border-style: solid;
  border-width: 4px;
  position: relative;
}

.marker-info-window {
  color: var(--color-primary);
}

.marker-info-window-image {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.info-window-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.info-window-button-container button {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border-color: var(--color-primary);
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
  width: 75px;
  height: 30px;
  cursor: pointer;
}

/* VIATOR PRODUCT SEARCH STYLES */

.viator-product-title {
  font-size: 18px;
  color: var(--color-secondary);
}

.viator-product-link {
  font-size: 16px;
  color: var(--color-secondary);
}

.viator-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

.viator-product-image {
  border-radius: 10px;
}

.viator-error-message {
  color: var(--color-secondary);
}

/* MAIL FORM STYLES */

.form-field-mail {
  margin-bottom: 16px;
  max-width: 50%;
  margin: auto;
}

.input-group {
  display: flex;
  align-items: stretch;
  max-width: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  justify-content: center;
  background-color: #fff;
  padding: 2px 10px 2px 2px;
}

.input-group input[type="email"] {
  flex-grow: 1; /* This ensures that the input field will take up all available space */
  padding: 8px;
  font-size: 16px;
  border: none; /* Remove border because the border is already applied to the parent .input-group */
  color: var(--color-text);
}

.input-group button {
  font-size: 22px;
  background-color: #fff;
  color: var(--color-text);
  border: none;
  cursor: pointer;
}

input[type="email"]:focus {
  outline: none;
}

.input-group:focus-within {
  outline: 2px solid var(--color-secondary);
  border-radius: 4px;
}

.envelope-button svg {
  color: var(--color-primary);
}

.envelope-button:disabled svg {
  /* color when disabled */
  color: var(--color-text-light);
}

.envelope-button:disabled {
  /* other styles when disabled */
  cursor: not-allowed !important;
}

.form-field-mail input[type="email"]::placeholder {
  color: var(--color-text-light); /* set lighter color for default text */
}

.form-field-mail input[type="email"]:not(:placeholder-shown) {
  color: var(--color-text-dark); /* set darker color for user input */
}

.mail-response {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MAIL STATUS STYLES */

#email-status {
  padding: 8px;
  font-size: 16px;
  color: var(--color-text);
  border-radius: 4px;
}

#email-status.success {
  background-color: #008000;
}

#email-status.error {
  background-color: #ff0000;
}

/* AFFILIATES STYLES */

.affiliate-teaser {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#affiliate_links {
  max-width: 800px;
  margin: 15px 15px;
}

#affiliate_links h5 {
  font-size: 20px;
  font-weight: 700;
}

#affiliate_links a {
  font-weight: bold;
  font-style: italic;
  color: var(--color-frangipani-peach);
}
/* Page Not Found */
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px;
}

/* Page Not Found */
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 16px;
}

/* styling of text */

.about-page h1 {
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.faq-page h1 {
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.privacy-policy h1 {
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.contact-page h1 {
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.contact-page a {
  color: var(--color-secondary);
}

.privacy-policy ul {
  list-style-position: inside;
  padding-left: 10px;
}

/* Media queries to deal with different screen sizes */

/* for very small screens, further adjust sizes */
/* included: iPhoneSE */

@media (max-width: 480px) {
  /* adjust based on when your layout starts to look bad */

  /* TRIP FORM MOBILE */
  .app-header {
    /* flex-direction: column; */
    padding: 0px;
  }

  .app-header h1 {
    font-size: 36px;
    padding: 20px 0px 20px 0px;
  }

  .logo {
    width: 50px;
    height: 50px;
  }

  .form-header h2 {
    font-size: 20px;
  }

  .form-header {
    padding: 10px 10px 15px;
  }

  .form-field input[type="text"],
  .form-field input[type="number"],
  .form-field .react-datepicker-wrapper,
  .form-field button[type="button"],
  .form-field .btn-generate-trip {
    width: 60%;
  }

  .react-datepicker__input-container input[type="text"] {
    width: 100%;
  }

  .location-image {
    height: 125px;
  }

  /* TRIP GUIDE MOBILE */
  .trip-guide {
    padding: 5px;
    padding-bottom: 0px;
  }

  .trip-itinerary h4 {
    font-size: 20px;
  }

  .trip-itinerary h5 {
    font-size: 18px;
  }

  .activity-container {
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    margin-bottom: 10px;
  }

  .activity-container:nth-child(odd) {
    flex-direction: column;
  }

  .activity-description {
    font-size: 14px;
    margin-bottom: 10px;
  }

  /* FACTSHEET TEASER MOBILE */
  .country-factsheet h4 {
    font-size: 20px;
  }

  .factsheet-teaser h5 {
    font-size: 16px;
  }

  .factsheet-teaser {
    margin-bottom: 15px;
  }

  .factsheet-teaser p {
    font-size: 14px;
    line-height: 1.4;
  }

  .factsheet-key {
    font-size: 14px;
  }

  .factsheet-items {
    margin-bottom: 16px;
  }

  .factsheet-items p {
    font-size: 14px;
    line-height: 1.4;
  }

  /* TAB HEADERS MOBILE */
  .tabs button {
    padding: 5px 10px; /* Reduce padding */
    font-size: 12px; /* Reduce font size */
    margin-right: 5px; /* Reduce spacing between buttons */
    margin-bottom: 10px;
  }

  .tabs button:hover,
  .tabs button.active {
    font-size: 14px; /* Adjusted font size for hover and active states */
    border: 3px solid var(--color-primary); /* Adjusted border for hover state */
  }

  /* VIATOR PRODUCT SEARCH MOBILE */
  .viator-product-title {
    font-size: 16px;
  }

  .viator-description {
    font-size: 12px;
  }

  .viator-product-image {
    width: 100%;
    max-width: 300px;
  }

  .viator-product-link {
    font-size: 14px;
  }

  /* TO BE ADDED - SCALING FOR SMALLER SCREENS */

  .form-field-mail {
    max-width: 80%;
  }

  .input-group input[type="email"] {
    font-size: 12px;
  }

  .input-group button {
    font-size: 18px;
  }

  #email-status {
    font-size: 13px;
    padding: 0px;
  }

  #affiliate_links h5 {
    font-size: 15px;
  }

  .affiliate-links {
    font-size: 14px;
  }

  .app-share-itinerary {
    font-size: 0.8em;
  }

  .social-icon-whatsapp {
    font-size: 1.5em;
  }

  .app-share-separator {
    font-size: 1em;
    margin: 0px 0px;
  }

  .social-icon-container {
    font-size: 1.5em;
  }

  .app-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin-top: 0px;
  }

  /* Page Not Found */
  .error-message {
    font-size: 14px;
  }

  /* adjust app size for very small screens */
  .app {
    max-width: 90%; /* adjust as needed */
    padding: 1vh;
    margin: 60px 60px;
  }
}

/* Remove margin for smaller screens */
/* @media screen and (max-width: 768px) {
  #id_date_picker {
    margin: 0;
  }
} */
